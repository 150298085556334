import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { buyerUrl, frontLaravelUrl } from '../../../config';

class FooterKatikaWidget extends Component {
    state = {
        title: 'Discover',
        links: [
            {
                path: '/press',
                text: 'Press'
            },
            {
                path: '/',
                text: 'Blog'
            }
        ]
    }
    render() {
        return (
            <>
                <div className="col-lg-3 col-md-3 col-sm-3 column-td-6">
                    <div className="footer-item">
                        <h4 className="footer__title">
                            {this.state.title}
                        </h4>
                        <ul className="list-items">
                            <li key={0}>
                                <a href={`${frontLaravelUrl}/press`} >Press</a>
                            </li>
                            <li key={1}>
                                <a target='_blank' href={`https://blog.katika.us`} >Blog</a>
                            </li>

                            
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}

export default FooterKatikaWidget;