import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import axios from 'axios';
import Button from 'react-bootstrap/Button'
import { Label } from 'reactstrap';
import { uToken } from '../../useToken';
import { ApiUrl, AutoApiKey } from '../../config';
import Autocomplete from 'react-google-autocomplete';
import { ToastContainer, toast } from 'react-toastify';

class UserForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            profile: '',
            user: '',
            externalData: false,
            address: '',
            auto: false,
            isHovered: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    // Toggle hover state
    handleMouseEnter = () => {
        this.setState({ isHovered: true });
    };

    handleMouseLeave = () => {
        this.setState({ isHovered: false });
    };

    handleSubmit(event, errors, values) {
        event.preventDefault();
        this.setState({ errors, values });
        var self = this;
        if (errors.length < 1) {
            axios.post(ApiUrl + 'updateBuyerProfile', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        self.setState({
                            auto: false,
                            externalData: false,
                        })
                        self.props.history.push(self.props.history.location.pathname)
                        // self.getProfile();
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }



    }

    componentDidMount() {
        this.getProfile();
    }

    getProfile = () => {
        var self = this;
        self.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'getBuyerProfile', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    profile: res.data.profile,
                    user: res.data.user,
                    address: res.data.user.address,
                    externalData: true,
                })
                let token = JSON.parse(localStorage.getItem('token'));
                let newtoken = {
                    full_name: res.data.user.fname+' '+res.data.user.lname,
                    image: res.data.user.image,
                    role:  res.data.user.role_id,
                    success: true,
                    token:  token.token,
                    id:  token.id,
                    analytic_id: token.analytic_id
                }
                localStorage.setItem('token',JSON.stringify(newtoken))


            })

            .catch(function (error) {
                console.log(error);
            });
    }


    render() {
        return (
            <>

                <div className="faq-forum">
                    <div className="contact-form-action">
                        <AvForm onSubmit={(event, errors, values) => this.handleSubmit(event, errors, values)} id="form-button" encType="multipart/form-data">
                            <AvGroup className="input-box">
                                <label className="label-text">First Name</label>
                                <label className="sub-label">This field is required.</label>
                                <AvInput
                                    className="form-control"
                                    name="fname"
                                    value={this.state.user.fname}
                                    required />
                                {/* <AvFeedback>This field is required.*</AvFeedback> */}
                            </AvGroup>

                            <AvGroup className="input-box">
                                <label className="label-text">Last Name</label>
                                <label className="sub-label">This field is required.</label>
                                <AvInput
                                    className="form-control"
                                    name="lname"
                                    value={this.state.user.lname}
                                    required />
                                {/* <AvFeedback>This field is required.*</AvFeedback> */}
                            </AvGroup>
                            <div className="input-box">
                                <label className="label-text">Gender</label>
                                <AvRadioGroup value={this.state.user.gender} name="gender" required errorMessage="Pick one!">
                                    <AvRadio label="Female" value="Female" />
                                    <AvRadio label="Male" value="Male" />
                                    <AvRadio label="Other" value="Other" />
                                </AvRadioGroup>
                            </div>

                            <AvGroup className="input-box">
                                <label className="label-text">Location</label>
                                <label className="sub-label">This field is required.</label>
                                {(this.state.auto) ?
                                    <>
                                        <Autocomplete
                                            apiKey={AutoApiKey}
                                            placeholder=""
                                            style={{ width: '100%' }}
                                            onPlaceSelected={(place) => {
                                                this.setState({
                                                    address: place.formatted_address
                                                })
                                            }}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') e.preventDefault();
                                            }}
                                            autoFocus={true}
                                            className="form-control"
                                            fields={[
                                                'address_components',
                                                'geometry.location',
                                                'place_id',
                                                'formatted_address'
                                            ]}
                                            // types={['(places)']}
                                            componentRestrictions={{ country: "us" }}
                                        />
                                        <AvInput
                                            type="hidden"
                                            className="form-control"
                                            name="address1"
                                            value={this.state.address}
                                            required />
                                        <AvFeedback>This field is required.*</AvFeedback>
                                    </>
                                    :
                                    <>
                                        <div onClick={() => this.setState({
                                            auto: true
                                        })} >
                                            <AvInput

                                                className="form-control"
                                                name="address"
                                                value={this.state.address}
                                                required />
                                            <AvFeedback>This field is required.*</AvFeedback>
                                        </div>
                                    </>
                                }
                            </AvGroup>

                            <AvGroup className="input-box">
                                <label className="label-text">Your Headline</label>
                                <label className="sub-label">Comma separated phrases (e.g. sushi, Reggae, puppies)</label>
                                <AvInput
                                    className="form-control"
                                    name="headline"
                                    value={this.state.profile.headline}
                                    required />
                                {/* <AvFeedback>This field is required.*</AvFeedback> */}
                            </AvGroup>

                            <AvGroup className="input-box">
                                <label className="label-text">Things I Love</label>
                                <label className="sub-label">Comma separated phrases (e.g. sushi, Reggae, puppies)</label>
                                <AvInput
                                    type="textarea"
                                    className="form-control"
                                    name="things_i_love"
                                    value={this.state.profile.things_i_love}
                                    required />
                                {/* <AvFeedback>This field is required.*</AvFeedback> */}
                            </AvGroup>

                            <AvGroup className="input-box">
                                <label className="label-text">Best fast food fried chicken</label>
                                <label className="sub-label">Comma separated phrases (e.g. sushi, Reggae, puppies)</label>
                                <AvInput
                                    className="form-control"
                                    name="fast_food"
                                    value={this.state.profile.fast_food}
                                    required />
                                {/* <AvFeedback>This field is required.*</AvFeedback> */}
                            </AvGroup>

                            <AvGroup className="input-box">
                                <label className="label-text">Favorite old school artist or group</label>
                                <label className="sub-label">Comma separated phrases (e.g. sushi, Reggae, puppies)</label>
                                <AvInput
                                    className="form-control"
                                    name="school"
                                    value={this.state.profile.school}
                                    required />
                                {/* <AvFeedback>This field is required.*</AvFeedback> */}
                            </AvGroup>

                            <AvGroup className="input-box">
                                <label className="label-text">My Favorite Movie</label>
                                <label className="sub-label">Comma separated phrases (e.g. sushi, Reggae, puppies)</label>
                                <AvInput
                                    className="form-control"
                                    name="movie"
                                    value={this.state.profile.movie}
                                    required />
                                {/* <AvFeedback>This field is required.*</AvFeedback> */}
                            </AvGroup>

                            <AvGroup className="input-box">
                                <label className="label-text">My Favorite Concert</label>
                                <label className="sub-label">Comma separated phrases (e.g. sushi, Reggae, puppies)</label>
                                <AvInput
                                    className="form-control"
                                    name="concert"
                                    value={this.state.profile.concert}
                                    required />
                                {/* <AvFeedback>This field is required.*</AvFeedback> */}
                            </AvGroup>

                            <AvGroup className="input-box">
                                <label className="label-text">Why You Should Read My Reviews</label>
                                <label className="sub-label">Comma separated phrases (e.g. sushi, Reggae, puppies)</label>
                                <AvInput
                                    className="form-control"
                                    name="why_reviews"
                                    value={this.state.profile.why_reviews}
                                    required />
                                {/* <AvFeedback>This field is required.*</AvFeedback> */}
                            </AvGroup>

                            <AvGroup className="input-box">
                                <label className="label-text">Favorite Zodiac Sign to hang out with with?</label>
                                <label className="sub-label">Comma separated phrases (e.g. sushi, Reggae, puppies)</label>
                                <AvInput
                                    className="form-control"
                                    name="zodiac_sing"
                                    value={this.state.profile.zodiac_sing}
                                    required />
                                {/* <AvFeedback>This field is required.*</AvFeedback> */}
                            </AvGroup>

                            <AvGroup className="input-box">
                                <label className="label-text">I Love...</label>
                                <label className="sub-label">Comma separated phrases (e.g. sushi, Reggae, puppies)</label>
                                <AvInput
                                    className="form-control"
                                    name="i_love"
                                    value={this.state.profile.i_love}
                                    required />
                                {/* <AvFeedback>This field is required.*</AvFeedback> */}
                            </AvGroup>



                            <div className="input-box">
                                <label className="label-text">Primary Language</label>
                                <AvField value={this.state.profile.language} type="select" name="language" required>
                                    <option value="English (United States)">English (United States)</option>
                                    <option value="English (Canada)">English (Canada)</option>
                                </AvField>

                            </div>


                            <div className="btn-box">
                                <Button type="submit" className="theme-btn border-0" variant="primary" >Save Changes</Button>
                                <Link to="/Path" >Cancel</Link>


                            </div>
                        </AvForm>
                    </div>
                </div>
                {/* <ToastContainer /> */}

            </>
        );
    }
}

export default UserForm;