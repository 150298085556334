import React, { useState } from 'react';
import SignUpOptions from "./SignUpOptions";
import { Link } from "react-router-dom";
import { AiOutlineUser } from 'react-icons/ai'
import { FaFile, FaRegEnvelope } from 'react-icons/fa'
import { FiLock } from 'react-icons/fi'
import { BsFileCode } from 'react-icons/bs'
import Select from "react-select";
import { ApiUrl, CaptchaKey } from '../../../config'
import Recaptcha from 'react-recaptcha';
import { ToastContainer, toast } from 'react-toastify';
import useToken from '../../../useToken';
import { useHistory } from "react-router-dom";

import axios from "axios";
import LoadingModal from '../../generic/LoadingModal';

const getMonthName = (month) => {
    const d = new Date();
    d.setFullYear(2023, month - 1, 1); // Using a constant year to avoid leap year effects
    const monthName = d.toLocaleString("default", { month: "short" });
    return monthName;
}


const months = [];
for (var i = 1; i <= 12; i++) {
    var m = {
        'value': ('0' + i).slice(-2),
        'label': getMonthName(i)
    }
    months.push(m)
}

const days = [];
for (var d = 1; d <= 30; d++) {
    var da = {
        'value': ('0' + d).slice(-2),
        'label': ('0' + d).slice(-2)
    }
    days.push(da)
}

const years = [];
for (var y = 1947; y <= 2025; y++) {
    var yy = {
        'value': y,
        'label': y
    }
    years.push(yy)
}

function SignUpBox({ title, subtitle }) {
    // begin loading modal
        const [isLoadingModal, setIsLoadingModal] = useState(false);

        const handleClose = () => {
            setIsLoadingModal(false);
        };
        
        const modalContent = <div>
                                <h5 className="text-black mb-2">Your Account is Being Created</h5>
                            </div>;

        const modalFooter = <></>;
    // end loading modal
    let history = useHistory();
    const { setToken } = useToken();
    const [captcha, setCaptcha] = useState(true);
    const [fname, setFname] = useState();
    const [lname, setLname] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [zipcode, setZipcode] = useState("");
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [image, setImage] = useState();
    const handleSubmit = async e => {
        e.preventDefault();
        var formData = new FormData();
        formData.append('fname', fname);
        formData.append('lname', lname);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('zip_code', zipcode);
        formData.append('day', day);
        formData.append('month', month);
        formData.append('year', year);
        formData.append('image', image);
        let type = "";
        if (image) {
            type = image.type;
        }

        if (captcha) {
            setIsLoadingModal(true);
            axios.post(ApiUrl + 'registerBuyer', formData, {
                headers: {
                    'Content-Type': type,
                }
            })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success("Sigup  successfully, Please verify your email.");
                    history.push('/verify-account/' + res.data.email)
                    // if (history.location.state.prevPath == '/' || history.location.state.prevPath == '/login') {
                    //     setTimeout(
                    //         () => history.push("/buyer-profile"),
                    //         4000
                    //     );
                    // } else {

                    //     history.goBack();
                    // }

                    // setToken(res.data);
                } else {
                    toast.error(res.data.message);
                }
                setIsLoadingModal(false);
            })
            .catch(function (error) {
                console.log(error);
                setIsLoadingModal(false);
            });
        } else {
            toast.error("Verify captcha");
        }


    }

    function onFileChange(e) {
        const file = e.target.files[0];
        setImage(file)
    }

    function callback() {
        // console.log('Done!!!!');
    }

    const verifyCallback = async response => {
        setCaptcha(true)
        // console.log(response)
    }
    return (
        <>
            <div className="billing-form-item mb-0">
                <div className="billing-title-wrap border-bottom-0 pt-0 pr-0 pl-0 pb-0 text-center">
                    <h3 className="widget-title font-size-28 pb-0">
                        {title}
                    </h3>
                    {/* <p className="font-size-16 font-weight-medium">
                        {subtitle}
                    </p> */}
                    {/* <p>By continuing, you agree to Katika’s <Link to='/terms-of-service'>Terms of Service</Link> and acknowledge Katika’s
                    <Link to='/privacy-policy'> Privacy Policy.</Link></p> */}
                </div>
                <div className="billing-content">
                    <div className="contact-form-action sign-up mt-0">
                        <form onSubmit={handleSubmit}>
                            <div className="row">

                                <SignUpOptions />

                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 user-info sign-up">
                                            <div className="input-box">
                                                <div className="form-group first">
                                                    <input onChange={e => setFname(e.target.value)} className="form-control" required type="text" name="fname" placeholder="First name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 user-info sign-up">
                                            <div className="input-box">
                                                <div className="form-group last">
                                                    <input onChange={e => setLname(e.target.value)} className="form-control" required type="text" name="lname" placeholder="Last name" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="input-box">
                                        <div className="form-group">
                                            <input onChange={e => setEmail(e.target.value)} className="form-control" type="email" name="email" placeholder="Enter email" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="input-box">
                                        <div className="form-group">
                                            <input onChange={e => setPassword(e.target.value)} className="form-control" required type="password" minLength="8" name="password" placeholder="Password" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="input-box">
                                        <div className="form-group">
                                            <input maxLength="5" onChange={e => setZipcode(e.target.value)} className="form-control" required type="text" name="zip_code" placeholder="Zip-Code" />
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-lg-12">
                                    <div className="input-box">
                                        <div className="form-group">
                                            <input className="form-control" onChange={onFileChange} type="file" name="image" />
                                        </div>
                                    </div>
                                </div> */}

                                <div className="col-lg-12">
                                    <div className="input-box date-of-birth text-start">
                                        <label className="label-text">Birthday</label> (optional)
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <Select
                                                        onChange={e => setMonth(e.value)}
                                                        placeholder="Month"
                                                        options={months}
                                                        name="month"
                                                        style={{ zIndex: "0 !important" }}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <Select
                                                        onChange={e => setDay(e.value)}
                                                        placeholder="Days"
                                                        options={days}
                                                        name="day"
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <Select
                                                        onChange={e => setYear(e.value)}
                                                        placeholder="Year"
                                                        options={years}
                                                        name="year"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-lg-12 mt-3 mb-3">
                                    <div className="terms-checkbox">
                                        <div className="checkbox">
                                            <input className="" required type="checkbox" />
                                        </div>
                                        <p className="term-url"> I agree to <Link to='/terms-of-service'>terms & conditions</Link></p>
                                    </div>
                                </div> */}

                                <div className="col-lg-12">
                                    <div className="captcha">

                                        <Recaptcha
                                            sitekey={CaptchaKey}
                                            render="explicit"
                                            verifyCallback={verifyCallback}
                                            onloadCallback={callback}
                                        />
                                    </div>
                                </div>


                                <div className="col-lg-12">
                                    <div className="btn-box">
                                        <button disabled={!captcha} className="theme-btn btn-login border-0 margin-top-20px" type="submit">
                                            Create account
                                        </button>
                                    </div>
                                    <div className="section-devid m-0 w-100">
                                        <div className="line m-0"></div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <p className="font-weight-medium text-center">
                                        Already have a Katika account? <Link to="/login" className="color-text">Login</Link>
                                    </p>
                                </div>

                                <div className="col-lg-12 mt-3 mb-3">
                                    <div className="terms-checkbox new-signup">
                                        <p className="term-url">By continuing, you agree to Katika’s <Link to='/terms-of-service'>Terms of Service</Link> and acknowledge <Link to='/privacy-policy'>Katika’s Privacy Policy</Link> . </p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* begin loading modal */}
                <LoadingModal
                    show={isLoadingModal}
                    onHide={handleClose}
                    title=""
                    content={modalContent}
                    footer={modalFooter}
                />
            {/* end loading modal */}
            <ToastContainer />
        </>
    );
}

export default SignUpBox;