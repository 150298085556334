import React, { useState, useEffect } from 'react';
import SignInOptions from "./SignInOptions";
import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import axios from "axios";
import { ApiUrl } from "../../../config"
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import useToken from '../../../useToken';
import { FaEye } from 'react-icons/fa';
// import LoadingModal from '../../generic/LoadingModal';



function LoginBoxPage({ title, subtitle, props, redirect, handler1, goFarward }) {
    // begin loading modal
        // const [isLoadingModal, setIsLoadingModal] = useState(false);

        // const handleClose = () => {
        //     setIsLoadingModal(false);
        // };
        
        // const modalContent = <div>
        //                         <h5 className="text-black mb-2">Logging You In</h5>
        //                     </div>;

        // const modalFooter = <></>;
    // end loading modal
    const [email, setUserName] = useState();
    const [password, setPassword] = useState();
    const [show, setShow] = useState('password');
    const { setToken } = useToken();
    let history = useHistory();
    // begin user token
        const [userAuthToken, setUserAuthToken] = useState('');
        const getUrlAuthToken = () => {
            const paramsMatch = window.location.href.match(/\?.+/);            
            let authToken = '';
            if (paramsMatch) {
                const params = new URLSearchParams(paramsMatch[0]);
                authToken = params.get('auth_token');
                // setUserAuthToken(authToken)
                handleUserLoginWithAuthToken(authToken);
                const newUrl = window.location.origin + window.location.pathname;
                window.history.replaceState({}, "", newUrl);
            } 
            console.log('user authToken = ', authToken);
            return authToken
        };
        useEffect(() => {
            getUrlAuthToken();
        }, []);

        // useEffect(() => {
        //     if(userAuthToken != '' && userAuthToken != undefined && userAuthToken != null) {
        //         handleUserLoginWithAuthToken(userAuthToken);
        //     }
        // }, [userAuthToken]);
        
    // end user token
    const handleUserLoginWithAuthToken = (userAuthToken) => {
        console.log('LoginBoxPage handleUserLoginWithAuthToken userAuthToken = ', userAuthToken);
        axios.post(ApiUrl + 'loginWithAuthToken', {
            userAuthToken
        }, {
            'Content-Type': 'application/json',
            'Authorization': 'JWT fefege...'
        })
        .then(function (response) {
            if (response.data.success === true) {
                setToken(response.data);
                if (handler1) {
                    handler1(true)
                }
                if (redirect != '1234') {
                    if (response.data.role == '1') {

                        if (goFarward != undefined) {
                            setTimeout(
                                () => history.go(goFarward),
                                4000
                            );
                        } else {

                            setTimeout(
                                () => history.push({
                                    pathname: '/user-profile',
                                    state: { prevPath: 'blogin' }
                                }),
                                4000
                            );
                        }
                    } else if (response.data.role == '2' || response.data.role == '0') {
                        setTimeout(
                            () => history.push("/dashboard"),
                            4000
                        );
                    }

                }
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if (email != '' && password != '') {
            // setIsLoadingModal(true);
            axios.post(ApiUrl + 'login', {
                email,
                password
            }, {
                'Content-Type': 'application/json',
                'Authorization': 'JWT fefege...'
            })
                .then(function (response) {
                    if (response.data.success === true) {
                        toast.success("Login Successful!");
                        setToken(response.data);
                        if (handler1) {
                            handler1(true)
                        }
                        if (redirect != '1234') {
                            if (response.data.role == '1') {

                                if (goFarward != undefined) {
                                    setTimeout(
                                        () => history.go(goFarward),
                                        4000
                                    );
                                } else {

                                    setTimeout(
                                        () => history.push({
                                            pathname: '/user-profile',
                                            state: { prevPath: 'blogin' }
                                        }),
                                        4000
                                    );
                                }
                            } else if (response.data.role == '2' || response.data.role == '0') {
                                setTimeout(
                                    () => history.push("/dashboard"),
                                    4000
                                );
                            }

                        }
                        // setTimeout(() => setIsLoadingModal(false), 4000);
                    } else {
                        toast.error(response.data.message);
                        // setIsLoadingModal(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    // setIsLoadingModal(false);
                });
            // setToken({"token":"abc"});
        }
    }

    return (
        <>


            <div className="border-bottom-0 pr-0 pl-0 pb-0 text-center">

                {/* <p className="font-size-16 font-weight-medium">
                        {subtitle} <Link to='/sign-up'> Sign up</Link>
                    </p>
                    <p>By logging in, you agree to Katika’s <Link to='/terms-of-service'>Terms of Service</Link> and */}
                {/* <Link to='/privacy-policy'> Privacy Policy.</Link></p> */}
            </div>

            <div className="contact-form-action text-start">
                <form onSubmit={handleSubmit}>
                    <div className="row">


                        {/* <div className="col-lg-12">
                                    <div className="account-assist mt-4 mb-4 text-center">
                                        <p className="account__desc">or</p>
                                    </div>
                                </div> */}
                        <div className="col-lg-12">
                            <div className="input-box">
                                <div className="form-group">
                                    {/* <label className="label-login">Your email</label> */}
                                    <input required="required" onChange={e => setUserName(e.target.value)} className="form-control" type="email" name="text" placeholder="Write your email" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="input-box">
                                <div className="form-group no-margin">
                                    {/* <label className="label-login">Password</label> */}
                                    <input required="required" onChange={e => setPassword(e.target.value)} className="form-control" type={show} name="text" placeholder="Password" />
                                    <span onClick={() => show == 'password' ? setShow('text') : setShow('password')} className="showPassword"><FaEye /></span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-12">
                            <div className="form-group no-margin">
                                <div className="custom-checkbox mr-0 d-flex align-items-center justify-content-between">
                                    <div>
                                        &nbsp;
                                    </div>
                                    <div>
                                        <Link to="/recover" className=" font-weight-medium">
                                            Forgot password?
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-12">
                            <div className="btn-box text-center">
                                <button className="btn btn-primary border-0 rounded-50 m-0" type="submit">
                                Sign In
                                </button>
                            </div>
                            <div className="section-devid">
                                <div className="line"></div>
                                <span>or</span>
                            </div>
                        </div>
                        <SignInOptions goFarward={goFarward} handler1={handler1} redirect={redirect} />
                    </div>
                </form>
            </div>
            {/* begin loading modal */}
            {/* <LoadingModal
                show={isLoadingModal}
                onHide={handleClose}
                title=""
                content={modalContent}
                footer={modalFooter}
            /> */}
            {/* end loading modal */}
            <ToastContainer />
        </>
    );
}

export default LoginBoxPage;

// LoginBox.propTypes = {
//     setToken: PropTypes.func.isRequired
// };