import React, { useEffect, useState } from 'react'
// import { FiChevronDown } from 'react-icons/fi'
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import $ from 'jquery';
import { useHistory } from "react-router-dom";
import useToken, { uToken } from '../../useToken';
import DashboardLogo from '../common/DashboardLogo';
import DashboardLogoPix from "../../assets/images/dashboard-logo.svg";
export default function Navbar() {
    const [navOpen, setNavOpen] = useState(false);
    const [token, setToken] = useState(!uToken() ? '' : uToken());
    let history = useHistory();

    const logoutBtnStyle = {
        backgroundColor: 'black',
        border: '2px solid black',
    };

    const logoutAnchorStyle = {
        color: 'white',
    };

    $(document).on('click', '.side-menu-ul li', function () {
        $(".side-menu-ul li").removeClass('active');
        $(this).toggleClass("active")
    })
    $(document).on('click', '.side-menu-ul li.active', function () {
        $(".side-menu-ul li.active").removeClass('active');
    })

    useEffect(() => {
    }, []);

    function LogoutBtn() {
        setToken('');
        localStorage.removeItem('token');
        // history.push("/");
        // setTimeout(
        //     () => history.push("/"),
        //     4000
        // );
    }
    
    return (
        <>
            <div className="main-menu-content d-none d-sm-block">
                <nav>
                    <Container>
                        <Row>
                            <Col lg={2} md={2}>
                                {/* <DashboardLogo  /> */}
                                <DashboardLogo url={DashboardLogoPix} />
                            </Col>

                            <Col lg={10} md={10}>
                                <ul className="top-nav">
                                    {/* <li> <Link to="/"> B·SCHOOL VIDEOS </Link>  </li>
                                    <li> <Link to="/"> SUPPORT </Link>  </li>
                                    <li> <Link to="/"> INBOX (1) </Link>  </li> */}
                                    {/* <li className="LogOut seller"> <Link onClick={LogoutBtn} to="/"> LOGOUT </Link>  </li> */}
                                    <li className="LogOut seller" style={window.location.href.includes("website") ? logoutBtnStyle : {}}> <Link onClick={LogoutBtn} to="/" style={window.location.href.includes("website") ? logoutAnchorStyle : {}}> LOGOUT </Link>  </li>
                                    
                                </ul>
                            </Col>
                        </Row>
                    </Container>

                </nav>
            </div>
            <div className="main-menu-dashboard small d-block d-sm-none">
                <nav>
                    <Container>
                        <Row>
                            <Col lg={2} md={2}>
                                {/* <DashboardLogo  /> */}
                                <DashboardLogo url={DashboardLogoPix} />
                            </Col>
                        </Row>
                    </Container>
                </nav>
                <div className="side-menu-open d-block d-sm-none" onClick={() => setNavOpen(!navOpen)}>
                    <span className="menu__bar"></span>
                    <span className="menu__bar"></span>
                    <span className="menu__bar"></span>
                </div>
            </div>
            <div className={navOpen ? 'side-nav-container active' : 'side-nav-container'}>
                <div className="humburger-menu">
                    <div className="humburger-menu-lines side-menu-close" onClick={() => setNavOpen(!navOpen)}></div>
                </div>
                <div className="side-menu-wrap side-bar-dashboard-logo">
                    <DashboardLogo url={DashboardLogoPix} />
                    <ul className="side-menu-ul">
                        <li className="LogOut seller"> <Link onClick={LogoutBtn} to="/"> LOGOUT </Link>  </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
