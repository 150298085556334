import React, { Component } from 'react'
import { IoIosArrowDown } from "react-icons/io";
import { RiThumbUpLine, RiThumbUpFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import review from '../../assets/images/useful-2.png';
import smile from '../../assets/images/smile-2.png';
import happy from '../../assets/images/cool-2.png';
import review_1 from '../../assets/images/review-1.png';
import smile_1 from '../../assets/images/smile-1.png';
import happy_1 from '../../assets/images/happy-1.png';
import { ApiUrl, frontLaravelUrl } from '../../config';
import axios from 'axios';
import Rater from 'react-rater';
import { Button } from 'react-bootstrap';
import $ from 'jquery';
import { uToken, uInfo } from '../../useToken';
import LoginBox from '../other/account/LoginBox';
import Modal from 'react-bootstrap/Modal'
import Rating from 'react-rating';
import Empty from '../../assets/images/empty.png';
import Half from '../../assets/images/half.png';
import Fill from '../../assets/images/fill.png';
import Like from '../../assets/images/like1.png';
import Comment from '../../assets/images/comment.png';
import Share from '../../assets/images/share1.png';
import Slider from "react-slick";
import Picker from 'emoji-picker-react';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { BsChevronUp, BsChevronDown, BsInfoCircle, BsDot } from 'react-icons/bs'
import { toast, ToastContainer } from 'react-toastify';
class PlaceListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search_data: {},
            items: [],
            chosenEmoji: {},
            commentBox: "",
            postID: 0,
            from: this.props?.from == 'Banner' ? true : false,

        };
        this.onHide = this.onHide.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handler1 = this.handler1.bind(this);
    }

    onHide() {
        this.setState({ LoginModal: false, GalleryModal: false });
    }
    handler1(e) {
        if (e) {
            this.setState({
                LoginModal: false,
            });

            this.props.handler2();
        }
        // this.props.history.go('/')
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            items: newProps.businesses,
            search_data: newProps.searchdata,
        });


    }
    componentDidMount() {
        this.setState({
            items: this.props.businesses,
            search_data: this.props.searchdata
        });


        // comment_reply_
        // handleReplyText1
        // 
    }

    onEmojiReplyClick = (event, emojiObject, id) => {
        let stuff = this.state['comment_reply_' + id];
        let text = stuff + emojiObject.emoji;
        this.setState({
            ['comment_reply_' + id]: text
        });
    };


    handleReplyText1 = (e, id) => {
        let text = e.target.value;
        this.setState({
            [e.target.name]: text
        });
    }



    handleReplyKeyDown(e, id, index, total_comments, commentIndex, replyId) {
        var self = this;
        if (e.key === 'Enter') {
            e.preventDefault();
            let comment = self.state['comment_reply_' + replyId];
            self.setState({
                ['comment_reply_' + replyId]: ''
            });
            axios.post(ApiUrl + 'addPostComment', { 'comment': comment, 'post_id': id, reply_id: replyId }, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {

                        let stateCopy = { ...self.state.items }
                        let oilSite = stateCopy[index]; //Get site by index

                        console.log(oilSite.comments[commentIndex])
                        stateCopy[index].comments[commentIndex] = res.data.comments;
                        //Add property to site
                        stateCopy[index] = oilSite;//update array
                        //Update the state
                        self.setState(stateCopy);





                        // let stateCopy = { ...self.state.items }
                        // let oilSite = stateCopy[index]; //Get site by index
                        // oilSite.total_comments = total_comments;
                        // oilSite.comments = [...oilSite.comments, res.data.comments]


                        // //Add property to site
                        // stateCopy[index] = oilSite;//update array
                        // //Update the state
                        // self.setState(stateCopy);
                    } else {
                        toast.error(res.data.message);
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    handleKeyDown(e, id, index, total_comments, replyId) {
        var self = this;
        if (e.key === 'Enter') {
            e.preventDefault();
            let comment = self.state['comment_' + id];
            self.setState({
                ['comment_' + id]: ''
            });
            axios.post(ApiUrl + 'addPostComment', { 'comment': comment, 'post_id': id, reply_id: replyId }, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        let data = {
                            full_name: uInfo().full_name,
                            image: uInfo().image,
                            comment: comment,
                            created_at: 'now',
                            replies: []
                        }

                        let stateCopy = { ...self.state.items }
                        let oilSite = stateCopy[index]; //Get site by index
                        oilSite.total_comments = total_comments;

                        oilSite.comments = [...oilSite.comments, res.data.comments]
                        //Add property to site
                        stateCopy[index] = oilSite;//update array
                        //Update the state
                        self.setState(stateCopy);
                    } else {
                        toast.error(res.data.message);
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    actionLike = (id, activty, index, total_like) => {
        var self = this;
        axios.post(ApiUrl + 'addBusinessPostLike', { 'is_like': activty, 'post_id': id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    let stateCopy = { ...self.state.items }
                    let oilSite = stateCopy[index]; //Get site by index
                    oilSite.is_like = activty;
                    oilSite.total_likes = total_like;
                    //Add property to site
                    stateCopy[index] = oilSite;//update array
                    //Update the state
                    self.setState(stateCopy);
                } else {
                    toast.error(res.data.message);
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    actionCommentLike = (id, activty, index, total_like, commentIndex, parent_id) => {

        console.log(id, activty, index, total_like, commentIndex, parent_id, 'id, activty, index, total_like, commentIndex, parent_id')
        var self = this;
        axios.post(ApiUrl + 'addPostCommentReaction', { 'is_like': activty, 'comment_id': id, 'parent_id': parent_id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {

                    let stateCopy = { ...self.state.items }
                    let oilSite = stateCopy[index]; //Get site by index

                    console.log(oilSite.comments[commentIndex])
                    stateCopy[index].comments[commentIndex] = res.data.comments;
                    //Add property to site
                    stateCopy[index] = oilSite;//update array
                    //Update the state
                    self.setState(stateCopy);




                    // let stateCopy = { ...self.state.items }
                    // let oilSite = stateCopy[index].comments; //Get site by index
                    // oilSite[commentIndex] =  res.data.comments;

                    // oilSite.comments = oilSite;
                    // //Add property to site
                    // stateCopy[index] = oilSite;//update array
                    // //Update the state
                    // self.setState(stateCopy);

                    // let stateCopy = { ...self.state.items }
                    // let oilSite = stateCopy[index]; //Get site by index
                    // let oilCommentLike = oilSite.comments[commentIndex];
                    // oilCommentLike.is_like = activty;
                    // oilCommentLike.total_likes = total_like;
                    // stateCopy[index] = oilSite;//update array
                    // self.setState(stateCopy);
                } else {
                    toast.error(res.data.message);
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }



    onEmojiClick = (event, emojiObject, id) => {
        let stuff = this.state['comment_' + id];

        console.log(stuff, 'ssss')
        let text = '';
        if (stuff != undefined) {
            text = stuff + emojiObject.emoji;
        } else {
            text = emojiObject.emoji;
        }

        this.setState({
            ['comment_' + id]: text
        });
    };


    handleText1 = (e, id) => {
        let text = e.target.value;
        this.setState({
            [e.target.name]: text
        });
    }

    truncateString(str, maxLength) {
        if (str.length > maxLength) {
            return str.slice(0, maxLength) + "...";
        } else {
            return str;
        }
    }


    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        // if (window.location.hash != '') {

        //     var elmnt = document.getElementById(window.location.hash.replace('#',''));
        //     console.log(elmnt ,'elmnt' ,window.location.hash)
        //     if (elmnt != null && elmnt != undefined) {
        //         elmnt.scrollIntoView({ behavior: 'smooth' });
        //         // $(window.location.hash)[0].scrollIntoView();
        //     }


        // }


        return (
            <div className="cities recent posts" style={{ paddingTop: this.state.from ? "0px" : "30px !important" }}>
                {this.state.items.map((item, i) => {
                    return (
                        <div id={`post${item.id}`} key={i + '-' + item.business_name} className={this.state.from ? 'col-lg-12 p-0' : 'col-lg-12 p-0'}>
                            <div className="card-item col-last first-col">
                                <div className="ReviewsWrote">
                                    {
                                        item.images.length > 0 && (

                                            <div className="card-image slider-gallery">
                                                <Slider {...settings}>
                                                    {
                                                        item.images.map((image, ki) => {
                                                            return (
                                                                <div key={ki} className="slide-slider addIframe">
                                                                    <a href={`${frontLaravelUrl}/block/details/${item.id}`} >
                                                                        {
                                                                            image.type == 'video' ? (
                                                                                <iframe 
                                                                                title={item.business_name}
                                                                                src={image.image}
                                                                                frameborder="0" allowfullscreen="allowfullscreen"></iframe>

                                                                                // <video controls
                                                                                //     preload="auto" loop=""   >
                                                                                //     <source type="video/mp4"
                                                                                //         src={image.image}>
                                                                                //     </source>
                                                                                // </video>
                                                                            ) :
                                                                                <img width={'500px'} height={'500px'} src={image.image} alt='Main images' />
                                                                        }
                                                                    </a>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Slider>
                                            </div>
                                        )
                                    }
                                    <div className="media m-round last-col">
                                        <div className="media-left">
                                            <div className="User_Info">
                                                <a href={`${frontLaravelUrl}/biz/${item.business_slug}`}>
                                                    <img width="100%" height="100%" className="media-object" src={item.business_logo} alt="business_logo" />
                                                </a>

                                                <div className="media-body m-body">
                                                    <h4 className="media-heading m-h4">
                                                        <a>{item.business_name}</a>
                                                    </h4>
                                                    <p className="m-p">{item.created_at}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="post-desc">
                                            <a href={`${frontLaravelUrl}/block/details/${item.id}`} >
                                                <h1>{item.title}</h1>
                                                <p className={`descs`}> {this.truncateString(item.description,300)} </p>
                                            </a>
                                        </div>

                                    </div>
                                    <div className={`media-body item-heading b-heading ${item.post_type_color_code}`}>
                                        <div className="bus-name">
                                            <h4 className="media-heading m-h4">
                                                <a>{item.business_name}</a>
                                            </h4>
                                            <span className="post-zipcode-meta" >{item.post_type_title} <BsDot /> Located in {item.zipcode}</span>
                                        </div>
                                        {
                                            item.link != null && (
                                                <div className="learn-btn"><a href={item.link} target={'_blank'} className="learn">
                                                    {
                                                        item.link_text != null ? item.link_text : "Learn More"
                                                    }
                                                </a></div>
                                            )
                                        }

                                    </div>
                                </div>
                                <div className="user_review">
                                    <div className={`like-commment`}>
                                        <span>{item.total_likes} Like</span>
                                    </div>
                                    <div onClick={() => this.setState({ SelectCommentBoxID: this.state.SelectCommentBoxID == item.id ? 0 : item.id })} className={`like-commment comment-open`}>
                                        <span>{item.total_comments} Comment</span>
                                    </div>
                                </div>
                                {(!uToken()) ?
                                    <div className="user_review post-reaction-count not-login">
                                        <Button variant="none" title="Like"
                                            onClick={() => this.setState({ LoginModal: true })}
                                            className={`btn btn-link card__img img-thumbnail default`}
                                        >
                                            <img width="100%" height="100%" src={Like} className="card__img img-thumbnail" alt="review" /><span>Like</span>
                                        </Button>

                                        <Button variant="none" title="Comment"
                                            onClick={() => this.setState({ LoginModal: true })}
                                            className={`btn btn-link card__img img-thumbnail default`}
                                        >
                                            <img width="100%" height="100%" src={Comment} className="card__img img-thumbnail" alt="smile" /><span>Comment</span>
                                        </Button>
                                    </div>
                                    :
                                    <div className='post-meta post-reaction-count'>
                                        <div className="user_review">

                                            {
                                                item.is_like == 0 ? (
                                                    <Button variant="none" title="Like"
                                                        onClick={() => this.actionLike(item.id, 1, i, item.total_likes + 1)}
                                                        className={`btn btn-link card__img img-thumbnail default`}
                                                    >
                                                        <span><RiThumbUpLine /> Like</span>
                                                    </Button>
                                                ) :
                                                    (
                                                        <Button variant="none" title="Like"
                                                            onClick={() => this.actionLike(item.id, 0, i, item.total_likes - 1)}
                                                            className={`btn btn-link card__img img-thumbnail `}
                                                        >
                                                            <span><RiThumbUpFill /> Like </span>
                                                        </Button>
                                                    )
                                            }

                                            <Button variant="none" title="Comment"
                                                onClick={() => { this.setState({ postID: item.id }); $('#comment_' + item.id).focus() }}
                                                className={`btn btn-link card__img img-thumbnail default`}
                                            >
                                                <img width="100%" height="100%" src={Comment} className="card__img img-thumbnail" alt="smile" /><span>Comment</span>
                                            </Button>
                                        </div>


                                    </div>

                                }

                                <div className='post-meta'>

                                    {
                                        uToken() && (
                                            <div className="user_review">

                                                <div className='comment-post-area'>

                                                    <div className="d-flex mt-3">
                                                        <div className="user-img-comment">
                                                            <img width="100%" height="100%" className="media-object" src={uInfo().image} alt="author-img" />
                                                        </div>

                                                        <textarea rows={1} className="pl-3 pt-1 my-0 storycoment textarea"
                                                            placeholder={`Write a comment…`}
                                                            name={`comment_${item.id}`}
                                                            value={this.state[`comment_${item.id}`]}
                                                            id={`comment_${item.id}`}
                                                            onChange={(e) => this.handleText1(e, item.id, i, item.total_comments + 1)}
                                                            onKeyDown={(e) => this.handleKeyDown(e, item.id, i, item.total_comments + 1, 0)}
                                                        ></textarea>

                                                        <div className='comment-emoji-area'>

                                                            <div className={this.state.showPicker ? 'Emoji-On' : 'Emoji-Off'} onClick={() => this.setState({ showPicker: !this.state.showPicker, SelectPickerID: item.id })}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>
                                                            </div>
                                                        </div>





                                                    </div>
                                                    <div>
                                                        {
                                                            this.state.showPicker && this.state.SelectPickerID == item.id && (

                                                                <Picker onEmojiClick={(event, object) => this.onEmojiClick(event, object, item.id)} />
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div className='comments'>



                                        <div className={`comment_area ${this.state.SelectCommentBoxID == item.id ? 'block' : 'none'} `}>

                                            {item.comments.slice(0, 1).map((comment, c) => {

                                                return (
                                                    <div key={c + 'a'} className="comment storyComment reply-coment">
                                                        <div className="coment-user">
                                                            <img className="avatar__img" alt="Comment"
                                                                src={comment.image} />
                                                            <div className="meta-data">
                                                                <span className="comment__author">{comment.full_name} <span className="time-com">{comment.created_at}</span></span>
                                                            </div>
                                                        </div>

                                                        <div className="comment-body">
                                                            <div className="msg-body">

                                                                <div className="d-flex">
                                                                    <p className="comment-content">{comment.comment}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="comment-time">
                                                                {/* {comment.created_at}  */}



                                                                <Button variant="none" title="Like"
                                                                    onClick={() => console.log('hi')}
                                                                    className={`btn btn-link card__img img-thumbnail default`}
                                                                >
                                                                    <span> {comment.total_likes} Like</span>
                                                                </Button>




                                                                {
                                                                    uToken() ? (
                                                                        <>
                                                                            <Button variant="none" title="Comment"
                                                                                onClick={() => this.setState({ postCommentReplyID: this.state.postCommentReplyID != comment.id ? comment.id : 0 })}
                                                                                className={`btn btn-link card__img img-thumbnail default`}
                                                                            >
                                                                                <span>Reply</span>
                                                                            </Button>
                                                                            {
                                                                                comment.is_like == 0 ? (
                                                                                    <Button variant="none" title="Like"
                                                                                        onClick={() => this.actionCommentLike(comment.id, 1, i, comment.total_likes + 1, c, comment.id)}
                                                                                        className={`btn btn-link card__img img-thumbnail default commentLikeButton`}
                                                                                    >
                                                                                        <span> <RiThumbUpLine /></span>
                                                                                    </Button>
                                                                                ) :
                                                                                    (
                                                                                        <Button variant="none" title="Like"
                                                                                            onClick={() => this.actionCommentLike(comment.id, 0, i, comment.total_likes - 1, c, comment.id)}
                                                                                            className={`btn btn-link card__img img-thumbnail commentLikeButton`}
                                                                                        >
                                                                                            <span><RiThumbUpFill /></span>
                                                                                        </Button>
                                                                                    )
                                                                            }
                                                                        </>

                                                                    )
                                                                        :
                                                                        <>
                                                                            <Button variant="none" title="Comment"
                                                                                onClick={() => this.setState({ LoginModal: true })}
                                                                                className={`btn btn-link card__img img-thumbnail default`}
                                                                            >
                                                                                <span>Reply</span>
                                                                            </Button>

                                                                            <Button variant="none" title="Like"
                                                                                onClick={() => this.setState({ LoginModal: true })}
                                                                                className={`btn btn-link card__img img-thumbnail commentLikeButton`}
                                                                            >
                                                                                <span><RiThumbUpLine /></span>
                                                                            </Button>




                                                                        </>
                                                                }
                                                            </div>

                                                            <div className='post-meta reply'>
                                                                <div className="user_review">

                                                                </div>
                                                            </div>
                                                            <div className={`user_review comment-reply ${this.state.postCommentReplyID == comment.id ? 'block' : 'none'} `}>

                                                                <div className='comment-post-area'>

                                                                    <div className="d-flex mt-3">
                                                                        <div className="user-img-comment">
                                                                            <img width="100%" height="100%" className="media-object" src={uInfo().image} alt="author-img" />
                                                                        </div>

                                                                        <textarea rows={1} className="pl-3 pt-1 my-0 storycoment textarea"
                                                                            placeholder="Write a reply..."
                                                                            name={`comment_reply_${comment.id}`}
                                                                            value={this.state[`comment_reply_${comment.id}`]}
                                                                            onChange={(e) => this.handleReplyText1(e, comment.id, i, item.total_comments + 1)}
                                                                            onKeyDown={(e) => this.handleReplyKeyDown(e, item.id, i, item.total_comments + 1, c, comment.id)}
                                                                        ></textarea>

                                                                        <div className='comment-emoji-area'>

                                                                            <div className={this.state.showPicker ? 'Emoji-On' : 'Emoji-Off'} onClick={() => this.setState({ showPicker: !this.state.showPicker, SelectPickerID: comment.id })}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            this.state.showPicker && this.state.SelectPickerID == comment.id && (
                                                                                <Picker onEmojiClick={(event, object) => this.onEmojiReplyClick(event, object, comment.id)} />
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="reple">
                                                            {comment.replies.map((reply, r) => {

                                                                return (
                                                                    <div key={c + '-' + r} className="comment storyComment reply-coment">
                                                                        <div className="coment-user">
                                                                            <img className="avatar__img" alt="Comment"
                                                                                src={reply.image} />
                                                                            <div className="meta-data">
                                                                                <span className="comment__author">{reply.full_name} <span className="time-com">{reply.created_at}</span></span>
                                                                            </div>
                                                                        </div>

                                                                        <div className="comment-body">
                                                                            <div className="msg-body">

                                                                                <div className="d-flex">
                                                                                    <p className="comment-content">{reply.comment}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="comment-time">
                                                                                {/* {comment.created_at}  */}



                                                                                <Button variant="none" title="Like"
                                                                                    onClick={() => console.log('hi')}
                                                                                    className={`btn btn-link card__img img-thumbnail default`}
                                                                                >
                                                                                    <span> {reply.total_likes} Like</span>
                                                                                </Button>



                                                                                {
                                                                                    uToken() ? (
                                                                                        <>

                                                                                            {
                                                                                                reply.is_like == 0 ? (
                                                                                                    <Button variant="none" title="Like"
                                                                                                        onClick={() => this.actionCommentLike(reply.id, 1, i, reply.total_likes + 1, c, comment.id)}
                                                                                                        className={`btn btn-link card__img img-thumbnail default commentLikeButton`}
                                                                                                    >
                                                                                                        <span> <RiThumbUpLine /></span>
                                                                                                    </Button>
                                                                                                ) :
                                                                                                    (
                                                                                                        <Button variant="none" title="Like"
                                                                                                            onClick={() => this.actionCommentLike(reply.id, 0, i, reply.total_likes - 1, c, comment.id)}
                                                                                                            className={`btn btn-link card__img img-thumbnail commentLikeButton`}
                                                                                                        >
                                                                                                            <span><RiThumbUpFill /></span>
                                                                                                        </Button>
                                                                                                    )
                                                                                            }
                                                                                        </>

                                                                                    ) : (
                                                                                        <>
                                                                                            <Button variant="none" title="Like"
                                                                                                onClick={() => this.setState({ LoginModal: true })}
                                                                                                className={`btn btn-link card__img img-thumbnail default commentLikeButton`}
                                                                                            >
                                                                                                <span> <RiThumbUpLine /></span>
                                                                                            </Button>
                                                                                        </>
                                                                                    )
                                                                                }


                                                                            </div>


                                                                            <div className={`user_review comment-reply ${this.state.postCommentReplyID == reply.id ? 'block' : 'none'} `}>

                                                                                <div className='comment-post-area'>

                                                                                    <div className="d-flex mt-3">
                                                                                        <div className="user-img-comment">
                                                                                            <img width="100%" height="100%" className="media-object" src={uInfo().image} alt="author-img" />
                                                                                        </div>

                                                                                        <textarea rows={1} className="pl-3 pt-1 my-0 storycoment textarea"
                                                                                            placeholder="Write a reply..."
                                                                                            name={`comment_reply_${reply.id}`}
                                                                                            value={this.state[`comment_reply_${reply.id}`]}
                                                                                            onChange={(e) => this.handleReplyText1(e, reply.id, i, reply.total_comments + 1)}
                                                                                            onKeyDown={(e) => this.handleReplyKeyDown(e, reply.id, i, reply.total_comments + 1, comment.id)}
                                                                                        ></textarea>

                                                                                        <div className='comment-emoji-area'>

                                                                                            <div className={this.state.showPicker ? 'Emoji-On' : 'Emoji-Off'} onClick={() => this.setState({ showPicker: !this.state.showPicker, SelectPickerID: reply.id })}>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        {
                                                                                            this.state.showPicker && this.state.SelectPickerID == reply.id && (
                                                                                                <Picker onEmojiClick={(event, object) => this.onEmojiReplyClick(event, object, reply.id)} />
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                            {item.comments.length > 1 && (

                                                <Accordion className="commentReply accordion accordion-item" allowZeroExpanded={true} id="accordionExample1">

                                                    <div className={'card'} key={i}>
                                                        <AccordionItem>

                                                            <AccordionItemPanel>
                                                                <div className="comment_area">
                                                                    {item.comments.slice(1, item.comments.length).map((comment, c) => {
                                                                        return (
                                                                            <div key={c + 1} className="comment storyComment reply-coment">
                                                                                <div className="coment-user">
                                                                                    <img className="avatar__img" alt="Comment"
                                                                                        src={comment.image} />
                                                                                    <div className="meta-data">
                                                                                        <span className="comment__author">{comment.full_name} <span className="time-com">{comment.created_at}</span></span>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="comment-body">
                                                                                    <div className="msg-body">

                                                                                        <div className="d-flex">
                                                                                            <p className="comment-content">{comment.comment}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="comment-time">
                                                                                        {/* {comment.created_at}  */}



                                                                                        <Button variant="none" title="Like"
                                                                                            onClick={() => console.log('hi')}
                                                                                            className={`btn btn-link card__img img-thumbnail default`}
                                                                                        >
                                                                                            <span> {comment.total_likes} Like</span>
                                                                                        </Button>




                                                                                        {
                                                                                            uToken() ? (
                                                                                                <>
                                                                                                    <Button variant="none" title="Comment"
                                                                                                        onClick={() => this.setState({ postCommentReplyID: this.state.postCommentReplyID != comment.id ? comment.id : 0 })}
                                                                                                        className={`btn btn-link card__img img-thumbnail default`}
                                                                                                    >
                                                                                                        <span>Reply</span>
                                                                                                    </Button>
                                                                                                    {
                                                                                                        comment.is_like == 0 ? (
                                                                                                            <Button variant="none" title="Like"
                                                                                                                onClick={() => this.actionCommentLike(comment.id, 1, i, comment.total_likes + 1, c + 1, comment.id)}
                                                                                                                className={`btn btn-link card__img img-thumbnail default commentLikeButton`}
                                                                                                            >
                                                                                                                <span> <RiThumbUpLine /></span>
                                                                                                            </Button>
                                                                                                        ) :
                                                                                                            (
                                                                                                                <Button variant="none" title="Like"
                                                                                                                    onClick={() => this.actionCommentLike(comment.id, 0, i, comment.total_likes - 1, c + 1, comment.id)}
                                                                                                                    className={`btn btn-link card__img img-thumbnail commentLikeButton`}
                                                                                                                >
                                                                                                                    <span><RiThumbUpFill /></span>
                                                                                                                </Button>
                                                                                                            )
                                                                                                    }
                                                                                                </>

                                                                                            )
                                                                                                :
                                                                                                <>
                                                                                                    <Button variant="none" title="Comment"
                                                                                                        onClick={() => this.setState({ LoginModal: true })}
                                                                                                        className={`btn btn-link card__img img-thumbnail default`}
                                                                                                    >
                                                                                                        <span>Reply</span>
                                                                                                    </Button>

                                                                                                    <Button variant="none" title="Like"
                                                                                                        onClick={() => this.setState({ LoginModal: true })}
                                                                                                        className={`btn btn-link card__img img-thumbnail commentLikeButton`}
                                                                                                    >
                                                                                                        <span><RiThumbUpLine /></span>
                                                                                                    </Button>




                                                                                                </>
                                                                                        }
                                                                                    </div>

                                                                                    <div className='post-meta reply'>
                                                                                        <div className="user_review">

                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={`user_review comment-reply ${this.state.postCommentReplyID == comment.id ? 'block' : 'none'} `}>

                                                                                        <div className='comment-post-area'>

                                                                                            <div className="d-flex mt-3">
                                                                                                <div className="user-img-comment">
                                                                                                    <img width="100%" height="100%" className="media-object" src={uInfo().image} alt="author-img" />
                                                                                                </div>

                                                                                                <textarea rows={1} className="pl-3 pt-1 my-0 storycoment textarea"
                                                                                                    placeholder="Write a reply…"
                                                                                                    name={`comment_reply_${comment.id}`}
                                                                                                    value={this.state[`comment_reply_${comment.id}`]}
                                                                                                    onChange={(e) => this.handleReplyText1(e, comment.id, i, item.total_comments + 1)}
                                                                                                    onKeyDown={(e) => this.handleReplyKeyDown(e, item.id, i, item.total_comments + 1, c + 1, comment.id)}
                                                                                                ></textarea>

                                                                                                <div className='comment-emoji-area'>

                                                                                                    <div className={this.state.showPicker ? 'Emoji-On' : 'Emoji-Off'} onClick={() => this.setState({ showPicker: !this.state.showPicker, SelectPickerID: comment.id })}>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                {
                                                                                                    this.state.showPicker && this.state.SelectPickerID == comment.id && (
                                                                                                        <Picker onEmojiClick={(event, object) => this.onEmojiReplyClick(event, object, comment.id)} />
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="reple">
                                                                                    {comment.replies.map((reply, r) => {

                                                                                        return (
                                                                                            <div key={c + '-' + r} className="comment storyComment reply-coment">
                                                                                                <div className="coment-user">
                                                                                                    <img className="avatar__img" alt="Comment"
                                                                                                        src={reply.image} />
                                                                                                    <div className="meta-data">
                                                                                                        <span className="comment__author">{reply.full_name} <span className="time-com">{reply.created_at}</span></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="comment-body">
                                                                                                    <div className="msg-body">

                                                                                                        <div className="d-flex">
                                                                                                            <p className="comment-content">{reply.comment}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="comment-time">
                                                                                                        {/* {comment.created_at}  */}



                                                                                                        <Button variant="none" title="Like"
                                                                                                            onClick={() => console.log('hi')}
                                                                                                            className={`btn btn-link card__img img-thumbnail default`}
                                                                                                        >
                                                                                                            <span> {reply.total_likes} Like</span>
                                                                                                        </Button>



                                                                                                        {
                                                                                                            uToken() ? (
                                                                                                                <>

                                                                                                                    {
                                                                                                                        reply.is_like == 0 ? (
                                                                                                                            <Button variant="none" title="Like"
                                                                                                                                onClick={() => this.actionCommentLike(reply.id, 1, i, reply.total_likes + 1, c + 1, comment.id)}
                                                                                                                                className={`btn btn-link card__img img-thumbnail default commentLikeButton`}
                                                                                                                            >
                                                                                                                                <span> <RiThumbUpLine /></span>
                                                                                                                            </Button>
                                                                                                                        ) :
                                                                                                                            (
                                                                                                                                <Button variant="none" title="Like"
                                                                                                                                    onClick={() => this.actionCommentLike(reply.id, 0, i, reply.total_likes - 1, c + 1, comment.id)}
                                                                                                                                    className={`btn btn-link card__img img-thumbnail commentLikeButton`}
                                                                                                                                >
                                                                                                                                    <span><RiThumbUpFill /></span>
                                                                                                                                </Button>
                                                                                                                            )
                                                                                                                    }
                                                                                                                </>

                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    <Button variant="none" title="Like"
                                                                                                                        onClick={() => this.setState({ LoginModal: true })}
                                                                                                                        className={`btn btn-link card__img img-thumbnail default commentLikeButton`}
                                                                                                                    >
                                                                                                                        <span> <RiThumbUpLine /></span>
                                                                                                                    </Button>
                                                                                                                </>
                                                                                                            )
                                                                                                        }


                                                                                                    </div>


                                                                                                    <div className={`user_review comment-reply ${this.state.postCommentReplyID == reply.id ? 'block' : 'none'} `}>

                                                                                                        <div className='comment-post-area'>

                                                                                                            <div className="d-flex mt-3">
                                                                                                                <div className="user-img-comment">
                                                                                                                    <img width="100%" height="100%" className="media-object" src={uInfo().image} alt="author-img" />
                                                                                                                </div>

                                                                                                                <textarea rows={1} className="pl-3 pt-1 my-0 storycoment textarea"
                                                                                                                    placeholder="Write a reply…"
                                                                                                                    name={`comment_reply_${reply.id}`}
                                                                                                                    value={this.state[`comment_reply_${reply.id}`]}
                                                                                                                    onChange={(e) => this.handleReplyText1(e, reply.id, i, reply.total_comments + 1)}
                                                                                                                    onKeyDown={(e) => this.handleReplyKeyDown(e, reply.id, i, reply.total_comments + 1, comment.id)}
                                                                                                                ></textarea>

                                                                                                                <div className='comment-emoji-area'>

                                                                                                                    <div className={this.state.showPicker ? 'Emoji-On' : 'Emoji-Off'} onClick={() => this.setState({ showPicker: !this.state.showPicker, SelectPickerID: reply.id })}>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.54-4.46a1 1 0 0 1 1.42-1.42 3 3 0 0 0 4.24 0 1 1 0 0 1 1.42 1.42 5 5 0 0 1-7.08 0zM9 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" /></svg>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                {
                                                                                                                    this.state.showPicker && this.state.SelectPickerID == reply.id && (
                                                                                                                        <Picker onEmojiClick={(event, object) => this.onEmojiReplyClick(event, object, reply.id)} />
                                                                                                                    )
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </AccordionItemPanel>
                                                            <AccordionItemHeading className="card-header post-view-header">
                                                                <AccordionItemButton className="reply-comment-shown  post-view btn btn-link d-flex align-items-center">

                                                                    <i className="plus post-view-button">View more comments</i>
                                                                    <i className="minus post-view-button">View less comments</i>
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                        </AccordionItem>
                                                    </div>
                                                </Accordion>
                                            )}





                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}



                <Modal show={this.state.LoginModal} onHide={this.onHide} size="md"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>  </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <h5>Login required to perform this action </h5> */}
                        <LoginBox handler1={this.handler1} redirect="1234" title="Log in to Katika" subtitle="New to Katika?" />
                    </Modal.Body>
                </Modal>


                <Modal show={this.state.openModal} onHide={this.onHide} size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title> Make an Appointment </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <iframe src={this.state.calandlyUrl} frameBorder="0" width="100%" height="350px" />
                    </Modal.Body>
                </Modal>

                <ToastContainer />
            </div>
        );
    }
}

export default PlaceListing;