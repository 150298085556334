import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';

const LoadingModal = ({ show, onHide, title, content, footer }) => {
    return (
        <div className="modal-form text-center">
            <div className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="LoadingModalLabel">
                <Modal show={show} onHide={onHide} size="md" backdrop="static" centered>
                    <div className="modal-content warning-list p-4">
                        <div className="modal-top border-0 p-0">
                            <div className="alert-content">
                                <div className="form-area">
                                    <div className="tabs-content">
                                        <section className="mt-3 mb-3 text-center">
                                            {content}
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default LoadingModal;