import React, { Component } from 'react';

class WidgetOpenHours extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: 'Opening Hours',
            hours: this.props.state,
        }
    }
    render() {
        return (
            <>
                <div className="sidebar-widget">
                    <div className="opening-hours">
                        <ul className="list-items">
                            {/* {this.state.hours.map((item, i) => {
                                return (
                                    <li key={i} className="d-flex days_time">
                                        <div className="days"> {item.day}</div>
                                        <strong className={item.close ? 'color-text' : 'font-weight-medium'}>{item.close ? item.start_time : item.start_time + ' - ' + item.end_time}
                                        </strong>
                                    </li>
                                )
                            })} */}
                            {this.state.hours.map((item, i) => {
                                return (
                                    <>
                                        <li key={i} className="d-flex days_time">
                                            <div className="days"> {item.day}</div>
                                            <strong className={item.close ? 'color-text' : 'font-weight-medium'}>
                                                {
                                                    item.closed ? 
                                                        <strong className="color-text">Closed</strong>
                                                    : item.open_24 ? <strong>Open 24 hours</strong> : item.close ? item.start_time : item.start_time + `${item.start_time != '' ? ' - ' : ''}` + item.end_time
                                                }
                                            </strong>
                                        </li>
                                        {!item.close && !item.closed && !item.open_24 && item.more && item.more.length > 0 && item.more.map((m, j) => {
                                            return (
                                                <li key={i} className="d-flex days_time">
                                                    <div className="days"> </div>
                                                    <strong className={'font-weight-medium'}>
                                                        {m.start_time != '' ? m.start_time + ' - ' : ''} {m.end_time}
                                                    </strong>
                                                </li>
                                            )
                                        })}
                                    </>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}

export default WidgetOpenHours;