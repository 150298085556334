import React, { Component } from 'react';
import AddCamera from "../../assets/images/add-camera.png"
import UpdateProfile from "../../assets/images/update-profile.png"
import FindFriend from "../../assets/images/find-friend.png"
import { Container, Row, Col } from "react-bootstrap";
import { FaUserFriends } from 'react-icons/fa'
import { GoStar } from 'react-icons/go'
import { AiFillCamera } from 'react-icons/ai'
import PhotoUploader3 from '../../components/addlisting/PhotoUploader3';
import { Link, useLocation } from 'react-router-dom';
import { uToken } from '../../useToken';
import { ApiUrl, FbId, FbSecertKey } from '../../config';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal'
import { ToastContainer, toast } from 'react-toastify';
import Facebook from "facebook-js-sdk";
const Style = ({ children }) => <style>{children}</style>;
class Directory extends Component {

    constructor(props) {
        super(props)
        this.state = {
            user: '',
            editPhotoModal: false,
            state: false,
            preview: null,
            photeUpdate: false,
            showOverlay: false,
        }
        this.onHide = this.onHide.bind(this);
    }

    onHide() {
        this.setState({ editPhotoModal: false });
    }


    componentDidMount() {
        this.getProfile();
    }

    close(message) {
        if (message) {
            // toast.success(message);
            this.getProfile();
            this.setState({
                editPhotoModal: false,
                photeUpdate: true
            })
            this.props.history.push(this.props.history.location.pathname)
        } else {
            this.setState({
                editPhotoModal: false,
                photeUpdate: false,
            })

        }
    }

    getProfile = () => {
        var self = this;
        axios.post(ApiUrl + 'getBuyerProfile', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    user: res.data.user,
                    state: true,
                    photeUpdate: false
                })

                let token = JSON.parse(localStorage.getItem('token'));
                let newtoken = {
                    full_name: res.data.user.fname + ' ' + res.data.user.lname,
                    image: res.data.user.image,
                    role: res.data.user.role_id,
                    success: true,
                    token: token.token,
                    id: token.id,
                    analytic_id: token.analytic_id
                }
                localStorage.setItem('token', JSON.stringify(newtoken))

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    onDeleteAccount = async () => {
        const facebook = new Facebook({
            appId: FbId,
            appSecret: FbSecertKey,
            redirectUrl: `${window.location.origin.toString()}/login`,
            graphVersion: "v10.0",
        });

        facebook.setAccessToken(this.state.user.fbtoken);

        await facebook
            .delete(`/${this.state.user.login_id}/permissions`)
            .then((response) => {
                console.log(response, 'console')
                if (response.data.success === true) {
                    localStorage.removeItem('token');
                    this.props.history.push('/');
                } else {
                    toast.error('Something is not right. Try again');
                }

            }).catch(function (error) {
                if (error.response) {
                    if (error.response.data.error.type == 'OAuthException') {
                        toast.error('Login again to perform this operation');
                    } else {
                        toast.error(error.response.data.error.type);
                    }
                }
            });
    }

    // Handle file input change to display image preview
    onDrop = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({ preview: reader.result }); // Set the preview
            };
            reader.readAsDataURL(file); // Create a data URL of the selected image

            console.log(this.state.preview, 'preview')
        }
        console.log(file, 'file')
        this.submitForm(e);
    }

    // Handle image upload as binary data (binary upload)
    submitForm = async (e) => {
        e.preventDefault();

        const file = this.fileInput.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file); // Append image file to form data

            try {
                const res = await axios.post(ApiUrl + 'updateProfileImage', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${uToken()}`
                    }
                });

                if (res.data.success === true) {
                    toast.success(res.data.message);
                    window.location.reload();
                    // this.setState({
                    //     user: {
                    //         ...this.state.user,
                    //         image: this.state.preview
                    //     },
                    //     preview: null // Reset preview after successful upload
                    // });

                } else {
                    toast.error(res.data.message);
                }
            } catch (error) {
                console.log(error);
                toast.error('Failed to upload image');
            }
        } else {
            toast.warning('Select an image to upload');
        }
    }
    
    // submitForm = async e => {
    //     e.preventDefault();
    //     // if (myFiles.length > 0) {

    //     //     var formData = new FormData();
    //     //     myFiles.map((item) =>
    //     //         formData.append('image', item)
    //     //     );

    //     //     // formData.append('business_images', myFiles);
    //     //     var type = myFiles[0].type;
    //     //     axios.post(ApiUrl + 'updateProfileImage', formData, {
    //     //         headers: {
    //     //             'Content-Type': type,
    //     //             'Authorization': `Bearer ${uToken()}`
    //     //         }
    //     //     })
    //     //         .then(function (res) {
    //     //             if (res.data.success === true) {
    //     //                 // props.closeModal(res.data.message)
    //     //                 toast.success(res.data.message);
    //     //             } else {
    //     //                 toast.error(res.data.message);
    //     //                 // props.closeModal(res.data.message)
    //     //             }
    //     //         })
    //     //         .catch(function (error) {
    //     //             console.log(error);
    //     //         });
    //     // } else {
    //     //     toast.warning('Select Images');
    //     // }
    // }


    render() {
        return (
            <>
                <Style>
                    {`
                        .profile-picture {
                            position: relative;
                            display: inline-block;
                        }

                        .profile-picture img {
                            width: 100%;
                            height: auto;
                            cursor: pointer;
                            border-radius: 50%;
                        }

                        .overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: rgba(0, 0, 0, 0.6); /* Black background with transparency */
                            color: white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            font-size: 1.2rem;
                            font-weight: bold;
                        }

                        .overlay span {
                            pointer-events: none; /* Ensures the overlay doesn't block clicks */
                        }

                    `}
                </Style>
                <Container>
                    <Row>
                        <Col lg={12} md={12} id="user-picture">
                            <div className="user-profile">
                                
                                {/* <form onSubmit={this.submitForm}>
                                    <div className="profile-picture">
                                        <img
                                            src={this.state.preview ? this.state.preview : this.state.user.image}
                                            alt="User"
                                            onClick={() => this.fileInput.click()}
                                        />
                                        <input
                                            type="file"
                                            ref={input => this.fileInput = input}
                                            onChange={this.onDrop}
                                            style={{ display: 'none' }}
                                        />
                                    </div>
                                </form> */}
                                <form onSubmit={this.submitForm}>
                                    <div className="profile-picture" onMouseEnter={() => this.setState({ showOverlay: true })} onMouseLeave={() => this.setState({ showOverlay: false })}>
                                        <img
                                            src={this.state.preview ? this.state.preview : this.state.user.image}
                                            alt="User"
                                            
                                        />
                                        <input
                                            type="file"
                                            ref={input => this.fileInput = input}
                                            onChange={this.onDrop}
                                            style={{ display: 'none' }}
                                        />
                                        {/* Overlay for Edit Photo */}
                                        {this.state.showOverlay && (
                                            <div className="overlay" onClick={() => this.fileInput.click()}>
                                                <span>Edit Photo</span>
                                            </div>
                                        )}
                                    </div>
                                </form>

                            </div>
                            <div className="user-information">
                                <div className="user-name">
                                    <h2>{this.state.user.fname} {this.state.user.lname}</h2>
                                    <h6>{this.state.user.address}</h6>
                                </div>
                                <ul className="UserSocialInfo">
                                    <li><FaUserFriends /> <strong>{this.state.user.total_friends}</strong> Friends</li>
                                    <li><span><GoStar /></span> <strong>{this.state.user.reviews}</strong> Reviews</li>
                                    <li><AiFillCamera /> <strong>{this.state.user.images}</strong> Photos</li>
                                </ul>
                            </div>
                            <div className="uploads-update">
                                <ul className="uploads">
                                    <li>
                                        <div className="upload-photos">
                                            <img src={AddCamera} alt="User" />
                                            {/* <Link to="/Path" >Add Profile Photos</Link> */}
                                            <button onClick={() => this.setState({ editPhotoModal: !this.state.editPhotoModal })} className="remove-btn-bg" >
                                                Add Profile Photos
                                            </button>
                                            {/* <PhotoUploader3 title="Add Profile Photos"/> */}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="upload-photos">
                                            <img src={UpdateProfile} alt="User" />
                                            <Link to="/update-buyer-profile" >Update your Profile</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="upload-photos">
                                            <img src={FindFriend} alt="User" />
                                            <Link to="/find-friends" >Find Friends</Link>
                                        </div>
                                    </li>

                                    {
                                        this.state.user.login_type == 'Facebook' && (
                                            <li>
                                                <div className="upload-photos">
                                                    <button onClick={() => { if (window.confirm('Are you sure you want to delete your account.')) this.onDeleteAccount() }} className='btn btn-link text-danger'>Delete my account</button>
                                                </div>
                                            </li>
                                        )
                                    }

                                </ul>
                            </div>

                        </Col>

                        <Modal show={this.state.editPhotoModal} onHide={this.onHide} >
                            <Modal.Header>
                                <Modal.Title> Update profile image
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <>
                                    <PhotoUploader3 closeModal={this.close.bind(this)} />
                                    {/* <input className="form-control" multiple="multiple" onChange={this.onFileChange} type="file" name="image[]" /> */}
                                </>
                            </Modal.Body>
                        </Modal>

                    </Row>
                </Container>



            </>
        );
    }
}

export default Directory;
